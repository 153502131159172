import React, { useState } from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
// import GreenLead from "../components/GreenLead";
import WhiteLead from "../components/WhiteLead";
import Landing from "../components/Landing";
// import FWImage from "../components/FWImage";
// import GreenThreeGrid from "../components/GreenThreeGrid";
import Model3D from "../components/Model3D";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
import styled from "styled-components";
import Wrapper from "../components/Layout/Wrapper";

import FWImage from "../components/FWImage";
import GreenLead from "../components/GreenLead";
import MainVideo from "../components/MainVideo";
import ContentGrid from "../components/ContentGrid";


const CustomMarker = (props: MarkerComponentProps) => {
  return (
    <Circle>
      <div>{props.itemNumber}</div>
    </Circle>
  );
};

export const Flex = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
  margin: 0 -15px;

  img,
  iframe {
    width: 100%;
  }

  p {
    font-family: "Oswald Light";
    line-height: 1.5;
  }

  & > div > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    &.backgroundOverlay {
      position: relative;

      .blockTitle {
        position: absolute;
        top: 0;
        color: white;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 10px;
        width: 100%;
      }

      .blockContent {
        position: absolute;
        bottom: 0;
        color: white;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 10px;
        width: 100%;
      }
    }

    &.hoverZoom {
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.05);
        span {
          background-color: rgba(255, 255, 255, 0.2);
          color: black;
        }
      }
    }
  }

  .blackBorder {
    border: 1px solid black;
    // padding: 20px;
  }

  .greenbg {
    // padding: 20px;
    color: white;
    background-color: #90be43;
    a {
      color: white !imoprtant;
    }
  }

  .defaultPadding {
    padding: 20px;
  }

  .grid1 {
    width: 100%;
    padding: 9px 14px;
  }
  .grid2 {
    @media (min-width: 768px) {
      width: 50%;
    }
    padding: 9px 14px;
  }

  .grid23 {
    @media (min-width: 768px) {
      width: calc(100% / 3 * 2);
    }
    padding: 9px 14px;
  }
  .grid3 {
    @media (min-width: 768px) {
      width: 33.333%;
    }
    padding: 9px 14px;
  }
  .grid34 {
    @media (min-width: 768px) {
      width: calc(100% / 4 * 3);
    }
    padding: 9px 14px;
  }
  .grid4 {
    @media (min-width: 768px) {
      width: 25%;
    }
    padding: 9px 14px;
  }
`;


const Circle = styled.div`
 & > div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(154, 189, 86, 0.6) !important;
    padding-top: 10px;
    color: white;
    text-align: center;
    font-size: 32px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: rgba(154, 189, 86, 1) !important;
    }
  }
`;

const StyledWrapper = styled.div`
  height: 400px;
  width:400px;
  * {
    box-shadow: none !important;
    background-color: transparent !important;
  }
`;


interface IndexProps {
  data: {
    cms: {
      produktseite: {
        siteId: number;
        title: string;
        children: [
          {
            title: string;
            slug: string;
            children: [
              {
                title: string;
                lange: string;
                hohe: string;
                breite: string;
                gewicht: string;
                systemsteineText: string;
                subtitle: string;
                visibleOnFrontPage: boolean;
                artikelNummer: string;
                videosImagesSystemsteineItem: [
                  {
                    bild: [{ url: string; title: string }];
                    videoUrl: string;
                    modell: [{ filename: string }];
                  }
                ];
              }
            ];
          }
        ];
      };
      lokalisierung: {
        lange: string;
        hohe: string;
        breite: string;
        gewicht: string;
        beiHerstellerBestellenButtonTextSystemsteineTabs: string;
        direktBestellenButtonTextSystemsteineTabs: string;
      };
      inhalt: {
        title: string;
        url: string;
        siteId: number;
        children: [
          {
            title: string;
            partnerText: string;
            children: [
              {
                title: string;
                id: string;
                logo: [{ url: string }];
                websiteUrl: string;
              }
            ];
          }
        ];
      };
      entry: {
        title: string;
        siteId: number;
        landingBild: [{ url: string; title: string }];
        landingTitel: string;
        columnGrunerHintergrundTexte: [
          {
            text: string;
            enabled: boolean;
          }
        ];
        whiteLead: string;
        fwImageGrunerBlockMatrix: {
          enabled: boolean;
          greenLead: string;
          fullWidthImage: [
            {
              url: string;
              title: string;
            }
          ];
        };
        homepageGrid: [
          {
            id: string;
            enabled: boolean;
            minHeight: number;
            elementType: string;
            blockImage: [
              {
                url: string;
                title: string;
              }
            ];
            blockImageBordered: boolean;
            imageSliderImages: [
              {
                url: string;
                title: string;
              }
            ];
            boxBackgroundimage: [
              {
                url: string;
                title: string;
              }
            ];
            blockStyle: string;
            blockTitle: string;
            blockContent: string;
            width: number;
            boxLink: string;
            hoverZoom: boolean;
          }
        ];
      };
    };
  };
}

export const IndexQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      produktseite: entry(slug: "produkte", siteId: [$siteId]) {
        siteId
        title
        children {
          title
          slug
          children {
            title
            ... on CMS_main_systemsteineItem_Entry {
              lange
              hohe
              breite
              gewicht
              systemsteineText
              subtitle
              visibleOnFrontPage
              artikelNummer
              videosImagesSystemsteineItem {
                ... on CMS_videosImagesSystemsteineItem_image_BlockType {
                  bild {
                    url
                    title
                  }
                }
                ... on CMS_videosImagesSystemsteineItem_youtubeVideo_BlockType {
                  videoUrl
                }
                ... on CMS_videosImagesSystemsteineItem_modell_BlockType {
                  modell {
                    filename
                  }
                }
              }
            }
          }
        }
      }
      lokalisierung: entry(type: "MetaDatenLokalisierung", siteId: [$siteId]) {
        ... on CMS_main_metaDatenLokalisierung_Entry {
          lange
          hohe
          breite
          gewicht
          beiHerstellerBestellenButtonTextSystemsteineTabs
          direktBestellenButtonTextSystemsteineTabs
        }
      }

      inhalt: entry(slug: "swiss-block", siteId: [$siteId]) {
        title
        siteId
        url

        children(type: "kategorie") {
          title
          ... on CMS_main_kategorie_Entry {
            partnerText
          }
          children {
            title
            
            ... on CMS_main_partnerStelle_Entry {
              id
              logo {
                url
              }
              websiteUrl
            }
          }
        }
      }
      entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_home_Entry {
          landingBild {
            url
            title
          }
          metaBeschreibung
          landingTitel
          columnGrunerHintergrundTexte {
            ... on CMS_columnGrunerHintergrundTexte_textColumn_BlockType {
              text
              enabled
            }
          }
          whiteLead
          fwImageGrunerBlockMatrix {
            ... on CMS_fwImageGrunerBlockMatrix_fwImageGrunerTextBlock_BlockType {
              greenLead
              enabled
              fullWidthImage {
                url
                title
              }
            }
          }
          homepageGrid {
            ... on CMS_homepageGrid_element_BlockType {
              id
              enabled
              minHeight
              imageSliderImages {
                url
                title
              }
              elementType
              blockImage {
                url
                title
              }
              blockImageBordered
              boxBackgroundimage {
                url
                title
              }
              blockStyle
              blockTitle
              blockContent
              width
              boxLink
              hoverZoom
            }
          }
        }
      }
    }
  }
`;

const Index: React.FC<IndexProps> = ({ data }) => {

  const [markers, setMarkers] = useState([
    {
      top: 10, //10% of the image relative size from top
      left: 50, //50% of the image relative size from left
    },
    {
      top: 20, //10% of the image relative size from top
      left: 10, //50% of the image relative size from left
    },
  ]);
  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      //links={data.cms.entry.localized}
      green
      beschreibung={data.cms.entry.metaBeschreibung}
    >
      <Landing
        image={data.cms.entry.landingBild[0]}
        text={data.cms.entry.landingTitel}
      />
      {/* <GreenThreeGrid inhalt={data.cms.entry.columnGrunerHintergrundTexte} /> */}
      <Model3D />

      <WhiteLead inhalt={data.cms.entry.whiteLead} />
      {/* 
      <Wrapper>
        <ImageMarker
          src="https://swissblo-prod.imgix.net/Entry-Page/Keyvisual_6550.jpg"
          markers={markers}
          markerComponent={CustomMarker}
        />
        <div>
          <h2>Marker 1 Content</h2>
          <p>Marker 1 Text</p>
        </div>
      </Wrapper>
      */}

      <Wrapper>
        <Flex>
          <MainVideo />
          <ContentGrid data={data} />
        </Flex>
      </Wrapper>
      {data.cms.entry.fwImageGrunerBlockMatrix.map((entry, index) => {
        if (!entry.enabled) return null;
        return (
          <div key={index}>
            <FWImage image={entry.fullWidthImage[0]} />
            <GreenLead inhalt={entry.greenLead} />
          </div>
        );
      })}
    </DefaultLayout>
  );
};

export default Index;
