import React from "react";
import styled from "styled-components";
import Imgix from "react-imgix";
import Flickity from "react-flickity-component";
import { breakpoints, colors, isBrowser } from "../styles/variables";
if (isBrowser) {
  require("flickity-as-nav-for");
}
import YouTubeEmbed from "./YouTubeEmbed";
import { imgix } from "../data/data";

const CarouselContainer = styled.div`
  max-width: ${breakpoints.desktop}px;
  overflow: hidden;
  margin: 0 auto;
`;

const MainCarousel = styled.div`
  img,
  div,
  iframe {
    width: 100%;
    height: 100%;
    height: 25vh;
    object-fit: cover;
    cursor: pointer;
  }

  *::marker {
    visibility: hidden;
    font-size: 0;
    display: none;
    content: "";
  }

  .flickity-button.flickity-prev-next-button //previous
  {
    /* width: 30px;
    position: absolute;
    top: 0; */
    display: none;
  }

  /* position dots in carousel */
  .flickity-page-dots {
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  /* white circles */
  .flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    opacity: 1;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.2);
    margin-right: 5px;
    &::marker {
      visibility: hidden;

      display: none;
      content: "";
    }

    &:marker {
      visibility: hidden;

      display: none;
      content: "";
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
  /* fill-in selected dot */
  .flickity-page-dots .dot.is-selected {
    background: ${colors.green};
  }
  /* iframe {
    pointer-events: none;
  } */

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    img,
    div,
    iframe {
      height: 55vh;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    img,
    div,
    iframe {
      height: 70vh;
    }
  }
`;

const NavCarousel = styled.div`
  margin-top: 50px;
  .flickity-button.flickity-prev-next-button //previous
  {
    display: none;
  }
  .carousel-cell {
    width: 25%; /* half-width */
    /* height: 100%; */
    height: 100px;
    margin: 0 auto;
    margin-right: 10px;
    cursor: pointer;
    overflow: hidden;

    &:last-of-type {
      margin-right: 0;
    }

    > img,
    div {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen AND (min-width: ${breakpoints.tablet}px) {
      height: 160px;
      width: 300px;
    }
  }
`;

const ImageCarousel = ({
  inhalt,
  index,
  setLightboxIsOpen,
  setLightboxContent,
  setLightboxIndex,
}) => {
  const openLightbox = (items, index) => {
    setLightboxContent(items);
    setLightboxIndex(index);
    setLightboxIsOpen(true);
  };

  const flickityOptions = {
    initialIndex: 0,
    pageDots: true,
    prevNextButtons: false,
  };

  const flickityOptions2 = {
    asNavFor: ".carousel" + index,
    contain: true,
  };

  return (
    <CarouselContainer>
      <>
        <MainCarousel>
          <Flickity
            className={"carousel" + index} // default ''
            elementType={"div"} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            //static // default false
          >
            {inhalt.map((item, index) => {
              console.log('item',item)
              return(
              <div
                className="carousel-cell"
                onClick={() => openLightbox(inhalt, index)}
              >
                {item.bild && item.bild.length > 0 ? (
                  <Imgix
                    src={item.bild[0].url}
                    imgixParams={imgix.fullImg}
                    htmlAttributes={{ alt: item.bild[0].title }}
                  />
                ) : item.videoUrl ? (
                  <iframe
                    src={
                      "https://www.youtube.com/embed/" +
                      item.videoUrl.split(".com/watch?v=")[1] +
                      "?modestbranding=1&playsinline=1&rel=0"
                    }
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                ) : (
                  ""
                )}
              </div>
            )})}
          </Flickity>
        </MainCarousel>
        <NavCarousel>
          <Flickity
            elementType={"div"} // default 'div'
            options={flickityOptions2} // takes flickity options {}
            disableImagesLoaded={false} // default false
          >
            {inhalt.map((item, index) => (
              <div className="carousel-cell">
                {item.bild && item.bild.length > 0 ? (
                  <Imgix
                    src={item.bild[0].url}
                    imgixParams={imgix.fullImg}
                    htmlAttributes={{ alt: item.bild[0].title }}
                  />
                ) : item.videoUrl ? (
                  <YouTubeEmbed url={item.videoUrl} prev />
                ) : (
                  ""
                )}
              </div>
            ))}
          </Flickity>
        </NavCarousel>
      </>
    </CarouselContainer>
  );
};

export default ImageCarousel;
