import React, { useState } from "react";
import { Keyboard, Navigation } from "swiper";
import { SwiperSlide } from "swiper/react";

import Imgix from "react-imgix";
import { imgix } from "../data/data";
import { superScriptRHelperHTML } from "../data/superscriptHelper";
import { PartnerSwiper } from "../templates/swissblock";
import PartnerItem from "./PartnerItem";
import ImageCarousel from "./ImageCarousel";
import { lightboxContentGlobal, lightboxIndexGlobal, lightboxOpenGlobal } from "../layouts/default";
interface ContentGridProps {
  data: any
}

const ContentGrid: React.FC<ContentGridProps> = ({ data }) => {
  return(
  <>
    {data.cms.entry.homepageGrid.map((entry, index) => {
            if (entry.elementType == "productSlider") {
              return (
                <div style={{ width: "100%" }}>
                  {/* Produkteslider */}
                  <PartnerSwiper
                    modules={[Navigation, Keyboard]}
                    navigation
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    a11y={{
                      prevSlideMessage: "Previous slide",
                      nextSlideMessage: "Next slide",
                    }}
                    spaceBetween={40}
                    slidesPerView={"auto"}
                    loop={true}
                  >
                    {data.cms && data.cms.produktseite && data.cms.produktseite.children &&
                      data.cms.produktseite.children.map((obj, index) => {
                        return (
                          <>
                            {obj.children
                              ? obj.children.map((item:any, index:any) => {
                                  if (item.visibleOnFrontPage) {
                                    return (
                                      <SwiperSlide
                                        key={index}
                                        className={"shadow"}
                                      >
                                        <div style={{ padding: "10px" }}>
                                          {item.videosImagesSystemsteineItem[0] && item.videosImagesSystemsteineItem[0].bild.length > 0 && item.videosImagesSystemsteineItem[0].bild[0].url ? ( <Imgix
                                            src={
                                              item
                                                .videosImagesSystemsteineItem[0]
                                                .bild[0].url
                                            }
                                            imgixParams={imgix.gridLogoImg}
                                            htmlAttributes={{
                                              alt: item
                                                .videosImagesSystemsteineItem[0]
                                                .bild[0].title,
                                                style: {objectFit: "contain", height:"180px" }
                                            }}
                                          />) : (<></>)}
                                          
                                          <h4
                                            dangerouslySetInnerHTML={{
                                              __html: superScriptRHelperHTML(
                                                item.title
                                              ),
                                            }}
                                          ></h4>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  }
                                })
                              : ""}
                          </>
                        );
                      })}
                  </PartnerSwiper>
                  {/* Produkteslider END */}
                </div>
              );
            } else if (entry.elementType == "partnerSlider") {
              return (
                <div style={{width:"100%"}}>
                  {/* Partnerslider */}
                  {data.cms.inhalt && data.cms.inhalt.children && data.cms.inhalt.children.map((entry, index) => {
                    return (
                      <PartnerSwiper
                        key={index}
                        modules={[Navigation, Keyboard]}
                        navigation
                        keyboard={{ enabled: true, onlyInViewport: true }}
                        a11y={{
                          prevSlideMessage: "Previous slide",
                          nextSlideMessage: "Next slide",
                        }}
                        spaceBetween={40}
                        slidesPerView={"auto"}
                        loop={true}
                      >
                        {entry.children.map((entry, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <PartnerItem
                                title={entry.title}
                                link={entry.websiteUrl}
                                logo={entry.logo[0].url}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </PartnerSwiper>
                    );
                  })}
                  {/* Partnerslider END */}
                </div>
              );
            } else if(entry.elementType == "imageSlider") {
                const [lightboxIsOpen, setLightboxIsOpen] = lightboxOpenGlobal();
                const [lightboxContent, setLightboxContent] = lightboxContentGlobal();
                const [lightboxIndex, setLightboxIndex] = lightboxIndexGlobal();


                const items = entry.imageSliderImages.map((image:any) => {
                  return {
                    bild: [{
                      ...image
                    }]
                  }
                })

                const openLightbox = (items:any) => {
                  setLightboxContent(items);
                  setLightboxIndex(index);
                  actuallyOpenLightbox();
                };
              const actuallyOpenLightbox = () => {
                setLightboxIsOpen(true);
              };
              if(items.length === 0){return null}
              return (
                <div style={{width:"100%"}}>
                  <ImageCarousel
                    inhalt={items}
                    index={1}
                    setLightboxIsOpen={setLightboxIsOpen}
                    setLightboxContent={setLightboxContent}
                    setLightboxIndex={setLightboxIndex}
                  /></div>
              );
            } else {
              const classes = entry.hoverZoom
                ? "hoverZoom " + entry.blockStyle
                : entry.blockStyle;
              const entryBGImage =
                (entry.boxBackgroundimage.length &&
                  entry.boxBackgroundimage[0].url) ||
                "";
              const entryImage =
                (entry.blockImage.length && entry.blockImage[0].url) || "";
              if (!entry.enabled) return null;
              return (
                <div
                  key={index}
                  className={
                    "grid" +
                    entry.width +
                    (entry.blockImageBordered ? " defaultPadding" : "")
                  }
                  style={{ minHeight: entry.minHeight + "px" }}
                >
                  <div
                    className={classes}
                    style={{
                      backgroundImage: "url(" + entryBGImage + ")",
                      cursor: entry.boxLink !== "" ? "pointer" : "default",
                      height: "100%",
                    }}
                    onClick={() => {
                      if (entry.boxLink !== "" && entry.boxLink !== null) {
                        document.location.href = entry.boxLink;
                      }
                    }}
                  >
                    {entry.blockImageBordered ? (
                      <>
                        {entry.blockTitle && <h1 style={{fontWeight:"bold"}} className="blockTitle">{entry.blockTitle}</h1>}
                        {entryImage !== "" ? (
                          <img
                            src={entryImage}
                            alt={entry.blockImage[0].title || entry.blockTitle}
                          />
                        ) : (
                          ""
                        )}
                        <div className="blockContent"
                          dangerouslySetInnerHTML={{
                            __html: entry.blockContent,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {entry.blockTitle && (
                          <h1 style={{fontWeight:"bold"}} className="defaultPadding blockTitle">{entry.blockTitle}</h1>
                        )}
                        {entryImage !== "" ? (
                          <img
                            src={entryImage}
                            alt={entry.blockImage[0].title || entry.blockTitle}
                          />
                        ) : (
                          ""
                        )}
                        <div
                          className="defaultPadding blockContent"
                          dangerouslySetInnerHTML={{
                            __html: entry.blockContent,
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            }
          })}
  </>)};

export default ContentGrid;
