import React from "react";
import styled from "styled-components";

const YouTubeEmbedDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);

    &.prev {
      pointer-events: none;
    }

    @media (min-aspect-ratio: 16/9) {
      height: 56.25vw;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 177.78vh;
    }
  }
`;

const YouTubeEmbed = ({ url, prev }) => {
    if(!url || !url.split(".com/watch?v=").length){return null}
  return(
  <YouTubeEmbedDiv>
    <iframe
      src={
        "https://www.youtube.com/embed/" +
        url.split(".com/watch?v=")[1] +
        "?fs=0&modestbranding=1&playsinline=1&controls=0&showinfo=0&rel=0"
      }
      frameBorder="0"
      allowFullScreen
      className={prev ? "prev" : ""}
      title=""
    ></iframe>
  </YouTubeEmbedDiv>
)};

export default YouTubeEmbed;
